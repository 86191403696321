import axios from 'axios'

export const destroySession = async (token: string) => {
  await axios.post(
    '/api/destroySession',
    {},
    {
      headers: {
        'X-JWT-Token': `Bearer ${token}`
      }
    }
  )
}
