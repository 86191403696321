import { getAuth } from 'firebase/auth'
import { app } from '@/utils/firebase'
import { destroySession } from '@/features/auth/api/destroySession'
import { destroyCookie } from 'nookies'

export const useSignOut = () => {
  const auth = getAuth(app)

  const signOut = async (token: string) => {
    await destroySession(token)
    destroyCookie(null, 'checkoutId', {
      path: '/'
    })
    await auth.signOut()
  }

  return {
    signOut
  }
}
