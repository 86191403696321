import { css } from '@emotion/react'
// import type { CSSObject, FlattenSimpleInterpolation, SimpleInterpolation } from 'styled-components'

// TODO: 型が any になってしまっているのでなんとかする
export const xs = (first: any, ...interpolations: any) => css`
  @media (max-width: 560px) {
    ${css(first, ...interpolations)}
  }
`
export const sm = (first: any, ...interpolations: any) => css`
  @media (max-width: 768px) {
    ${css(first, ...interpolations)}
  }
`
export const md = (first: any, ...interpolations: any) => css`
  @media (max-width: 1336px) {
    ${css(first, ...interpolations)}
  }
`

const size: any = {
  bpSm: '440.3333px',
  bpMd: '768.3333px',
  bpLg: '1024.3333px',
  bpXl: '1412.3333px'
}
export const media: any = {
  bpSm: `screen and (max-width: ${size.bpSm})`,
  bpMd: `screen and (max-width: ${size.bpMd})`,
  bpLg: `screen and (max-width: ${size.bpLg})`,
  bpXl: `screen and (max-width: ${size.bpXl})`
}
