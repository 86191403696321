export const appUrls = {
  signUp: () => '/signup/',
  aboutAldea: () => '/about-aldea/',
  aboutAldeaRegistration: () => '/about-aldea/registration',
  contact: () => '/contact/',
  contactThankyou: () => '/contact/thankyou/',
  profile: () => '/profile/',
  tokusho: () => '/tokusho/',
  termsAldea: () => '/terms-aldea/',
  privacyPolicy: () => '/privacy-policy/',
  sitePolicy: () => '/site-policy/',

  news: {
    index: () => '/news/',
    detail: (id: string) => `/news/${id}`
  },
  aldea: {
    index: () => '/aldea/',
    news: {
      index: () => '/aldea/news/',
      detail: (id: string) => `/aldea/news/${id}`
    },
    posts: {
      index: () => '/aldea/posts/',
      detail: (id: string) => `/aldea/posts/${id}`
    },
    login: () => '/aldea/login/',
    mypage: {
      index: () => '/aldea/mypage/',
      edit: {
        index: () => '/aldea/mypage/edit',
        email: () => '/aldea/mypage/edit/email'
      },
      forms: {
        index: () => '/aldea/mypage/forms'
      },
      events: {
        detail: (productId: string) => `/aldea/mypage/events/${productId}`
      }
    },
    shop: {
      index: () => '/aldea/shop/',
      detail: (id: string) => `/aldea/shop/${id}/`,
      cart: () => '/aldea/shop/cart/'
    },
    forgotPassword: () => '/aldea/forgot-password/'
  }
}
