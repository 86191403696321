/** @jsxImportSource @emotion/react */
import React, { FC } from 'react'
// import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { media } from '@/components/media'

interface Props {
  label: string
  subLabel?: string
  color?: 'beige' | 'white' | 'gray' | 'black'
  size?: 'normal' | 'large' | 'small'
  className?: string
  as?: 'button' | 'span'
  action?: () => void
  disabled?: boolean
  type?: 'submit' | 'button'
}

export const ButtonDefault: FC<Props> = ({
  label,
  subLabel,
  color = 'white',
  size = 'normal',
  className,
  as = 'button',
  action,
  disabled,
  type = 'submit'
}) => {
  const buttonColor = `__${color}`
  const buttonSize = `__${size}`

  return as !== 'span' && action ? (
    <button
      type={type}
      className={`${buttonColor} ${buttonSize} ${className} ${disabled ? '__disabled' : ''}`}
      css={buttonStyle}
      disabled={disabled}
      onClick={() => {
        action()
      }}
    >
      <span className='block'>{label}</span>
      {subLabel && <span className='subText'>{subLabel}</span>}
    </button>
  ) : (
    <span className={`${buttonColor} ${buttonSize} ${className}`} css={buttonStyle}>
      <span className='block'>{label}</span>
      {subLabel && <span className='subText'>{subLabel}</span>}
    </span>
  )
}

const buttonStyle = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: auto;
  padding: 8px 20px;
  font-size: 0.75rem;
  color: var(--color-black);
  letter-spacing: 0.1em;
  line-height: 2;
  outline: 1px var(--color-black) solid;
  outline-offset: -1px;
  cursor: pointer;
  @media ${media.bpLg} {
    font-size: 0.9375rem;
    padding: 12px 20px;
  }
  span {
    white-space: nowrap;
    text-align: inherit;
    width: 100%;
    &.subText {
      text-align: right;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
  }
  /* color */
  &.__white {
    color: var(--color-black);
    background: var(--color-white);
    @media (hover: hover) {
      &:hover {
        background: var(--color-beige);
      }
    }
  }
  &.__beige {
    color: var(--color-black);
    background: var(--color-beige);
  }
  &.__gray {
    color: var(--color-white);
    background: var(--color-gray-500);
    outline: 1px var(--color-gray-500) solid;
  }
  &.__black {
    color: var(--color-white);
    background: var(--color-black);
    outline: 1px var(--color-black) solid;
    @media (hover: hover) {
      &:hover {
        color: var(--color-black);
        background: var(--color-beige);
      }
    }
  }
  /* size */
  &.__large {
    padding: 24px 32px;
    font-size: 1rem;
    letter-spacing: 0.1em;
    line-height: 2;
    span {
      width: 100%;
      text-align: center;
    }
  }
  &.__small {
    /* padding: 6px 8px; */
    font-size: 0.75rem;
    letter-spacing: 0.1em;
    line-height: 1;
    @media ${media.bpLg} {
      font-size: 0.9375rem;
      padding: 12px 20px;
    }
  }
  /* status */
  &.__disabled {
    color: var(--color-white);
    background: var(--color-gray-500);
    outline: 1px var(--color-gray-500) solid;
    cursor: not-allowed;
    @media (hover: hover) {
      &:hover {
        color: var(--color-white);
        background: var(--color-gray-500);
        outline: 1px var(--color-gray-500) solid;
      }
    }
  }
`
