import { useEffect, useState } from 'react'
import { shopifyClient } from '@/utils/shopifyClient'
import type { Product, Checkout, MailingAddressInput, MailingAddress } from 'shopify-buy'
// nookies を使ってクッキーを取得する
import { setCookie, parseCookies } from 'nookies'

export async function getCheckout() {
  const cookies = parseCookies()
  const checkoutId = cookies.checkoutId
  if (checkoutId) {
    try {
      const chk = await shopifyClient.checkout.fetch(checkoutId)
      if (!chk.completedAt) return chk
    } catch (error) {
      console.error(error)
    }
  }
  const checkout = await shopifyClient.checkout.create()
  setCookie(null, 'checkoutId', checkout.id, {
    path: '/',
    maxAge: 60 * 60 * 24 // 1日
  })
  return checkout
}

export const useShopify = () => {
  const [checkout, setCheckout] = useState<Checkout | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      const checkout = await getCheckout()
      setCheckout(checkout)
    })()
  }, [])
  function getProductId(product: Product) {
    const ids = product.id.split('/')
    return ids[ids.length - 1]
  }

  async function updateCheckoutEmail(email: string) {
    if (!checkout) return
    const updateCheckout = await shopifyClient.checkout.updateEmail(checkout.id, email)
    setCheckout(updateCheckout)
  }
  async function updateShippingAddress(shippingAddress: MailingAddressInput) {
    if (!checkout) return
    const updateCheckout = await shopifyClient.checkout.updateShippingAddress(
      checkout.id,
      shippingAddress
    )

    setCheckout(updateCheckout)
  }

  async function updateCheckoutAttributes(customAttributes: { key: string; value: string }[]) {
    if (!checkout) return
    const updateCheckout = await shopifyClient.checkout.updateAttributes(checkout.id, {
      customAttributes
    })
    setCheckout(updateCheckout)
  }

  /**
   * カートに商品を追加する
   * @param variantId
   * @param quantity
   * @param variantCount カート画面でselectedOptionsの表示制御のため設定
   * @returns
   */
  async function addLineItem(variantId: string, quantity: number, variantCount: number) {
    if (!checkout) return
    const updateCheckout = await shopifyClient.checkout.addLineItems(checkout.id, [
      {
        variantId,
        quantity,
        customAttributes: [{ key: 'variantCount', value: String(variantCount) }]
      }
    ])
    setCheckout(updateCheckout)
  }

  /**
   * カートの商品の数量を変更する
   * @param lineItemId
   * @param quantity
   * @returns
   */
  async function updateLineItem(lineItemId: string, quantity: number) {
    if (!checkout) return
    const updateCheckout = await shopifyClient.checkout.updateLineItems(checkout.id, [
      { id: lineItemId, quantity }
    ])
    setCheckout(updateCheckout)
  }

  /**
   * カートから商品を削除する
   * @param lineItemId
   * @returns
   */
  async function removeLineItem(lineItemId: string) {
    if (!checkout) return
    const updateCheckout = await shopifyClient.checkout.removeLineItems(checkout.id, [lineItemId])
    setCheckout(updateCheckout)
  }

  return {
    checkout,
    shopifyClient,
    getProductId,
    updateCheckoutEmail,
    updateCheckoutAttributes,
    updateShippingAddress,
    addLineItem,
    updateLineItem,
    removeLineItem
  }
}
