import React, { FC } from 'react'
import styled from '@emotion/styled'
import Link from 'next/link'

import { media } from '@/components/media'
import { useSignOut } from '@/hooks/useSignOut'
import { useRouter } from 'next/router'
import { ButtonDefault } from '@/components/parts/buttons/ButtonDefault'
import { appUrls } from '@/utils/appUrls'

interface Props {
  className?: string
  color?: string
  token: string
}

export const GlobalFooter: FC<Props> = ({ className, color, token }) => {
  const router = useRouter()
  const { signOut } = useSignOut()
  const inputColor = `__${color}`
  const isLogin = !!token

  const onClickLogout = async () => {
    await signOut(token)
    await router.push(appUrls.aldea.login())
  }
  return (
    <FooterWrap className={`${className} ${inputColor}`}>
      {/* <CookieConsent cookieName='hz-village-consent'>
        This website uses cookies to enhance the user experience.
      </CookieConsent> */}
      <div className='flexWrap'>
        <div className='flexContent'>
          <Link href={appUrls.news.index()}>
            <a className='textLink'>
              <span>NEWS</span>
            </a>
          </Link>
          <Link href={appUrls.aboutAldea()}>
            <a className='textLink'>
              <span>FANCLUB: ALDEA</span>
            </a>
          </Link>
          <Link href={appUrls.profile()}>
            <a className='textLink'>
              <span>PROFILE</span>
            </a>
          </Link>
          <Link href={appUrls.contact()}>
            <a className='textLink'>
              <span>CONTACT</span>
            </a>
          </Link>
        </div>
        <div className='flexContent'>
          <a
            className='textLink'
            target='_blank'
            rel='noreferrer'
            href='https://twitter.com/akinan_official'
          >
            <span>Twitter</span>
          </a>
          <a
            className='textLink'
            target='_blank'
            rel='noreferrer'
            href='https://youtube.com/@akinanakamoriofficial'
          >
            <span>YouTube</span>
          </a>
        </div>
        <div className='flexContent'>
          <div className='linkList'>
            <Link href={appUrls.sitePolicy()}>
              <a className='textSubLink'>
                <span>サイトポリシー</span>
              </a>
            </Link>
            <Link href={appUrls.privacyPolicy()}>
              <a className='textSubLink'>
                <span>プライバシーポリシー</span>
              </a>
            </Link>
            <Link href={appUrls.termsAldea()}>
              <a className='textSubLink'>
                <span>ALDEA利用規約</span>
              </a>
            </Link>
            <Link href={appUrls.tokusho()}>
              <a className='textSubLink'>
                <span>特定商取引法に基づく表記</span>
              </a>
            </Link>
          </div>
          <div className='mt-16'>
            {isLogin ? (
              <ButtonDefault
                label='ログアウト'
                subLabel='FANCLUB: ALDEA'
                className='text-left'
                action={() => onClickLogout()}
                type='button'
              />
            ) : (
              <>
                <Link href={appUrls.aldea.login()}>
                  <a>
                    <ButtonDefault
                      label='ログイン'
                      subLabel='FANCLUB: ALDEA'
                      className='text-left'
                      type='button'
                    />
                  </a>
                </Link>
                <Link href={appUrls.aboutAldea()}>
                  <a>
                    <ButtonDefault
                      label='新規入会'
                      subLabel='FANCLUB: ALDEA'
                      color='black'
                      className='mt-2'
                      type='button'
                    />
                  </a>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
      <p className='copyright font-12px'>©copyright AKINA NAKAMORI</p>
    </FooterWrap>
  )
}

const FooterWrap = styled.footer`
  width: 100%;
  max-width: var(--bp-xl);
  margin: 128px auto 0;
  padding: 0 20px 20px;
  background: #ffffff;
  @media ${media.bpLg} {
    padding: 20px;
    padding: 0 20px 16px;
  }
  &.__beige {
    background: var(--color-beige);
  }
  .linkList {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
  }
  .flexWrap {
    display: flex;
    gap: 0 30px;
    @media ${media.bpLg} {
      flex-direction: column;
      gap: 32px 0;
    }
  }
  .flexContent {
    width: 100%;
    border-top: 1px var(--color-black) solid;
  }
  .textLink {
    display: block;
    margin-top: 20px;
    font-size: 1.25rem;
    font-family: 'EB Garamond', 'Noto Serif JP', '游明朝', 'Yu Mincho', YuMincho,
      'Hiragino Mincho Pro', serif;
    letter-spacing: 0.1em;
    line-height: 1.25;
    &:hover {
      span {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
          width: 100%;
          height: 1px;
          background: var(--color-black);
        }
      }
    }
    & + .textLink {
      margin-top: 12px;
    }
  }
  .textSubLink {
    font-size: 0.75rem;
    letter-spacing: 0.1em;
    line-height: 2;
    &:hover {
      span {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
          width: 100%;
          height: 1px;
          background: var(--color-black);
        }
      }
    }
  }
  .copyright {
    @media ${media.bpLg} {
      margin-top: 48px;
    }
  }
`
